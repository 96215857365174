<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">
        Personal Basic Information
      </h3>
      <div>
        <el-button icon="el-icon-user" @click="drawer = true">Search Filter</el-button>
        <el-button type="primary" icon="el-icon-user" @click="dialog = true">Add New Record</el-button>
      </div>
    </div>
    <el-dialog title="View Record" :visible.sync="dialogView" fullscreen  width="100%">
      <el-row>
        <el-col :span="12">
          <el-descriptions title="BASIC DETAILS" direction="horizontal" :column="1" border>
            <el-descriptions-item label="Company">{{viewdata[0].bc_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Paymaster">{{viewdata[0].paymaster_em_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Lastname">{{viewdata[0].lastname}}</el-descriptions-item>
            <el-descriptions-item label="Firstname">{{viewdata[0].firstname}}</el-descriptions-item>
            <el-descriptions-item label="Middlename">{{viewdata[0].middlename}}</el-descriptions-item>
            <el-descriptions-item label="Maiden Middle Name">{{viewdata[0].maiden_lastname}}</el-descriptions-item>
            <el-descriptions-item label="Suffix">{{viewdata[0].suffix_bg4_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Nickname">{{viewdata[0].nickname}}</el-descriptions-item>
            <el-descriptions-item label="Sex">{{viewdata[0].sex}}</el-descriptions-item>
            <el-descriptions-item label="Gender">{{viewdata[0].gender_bg4_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Religion">{{viewdata[0].religion_bg4_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Date of Birth">{{viewdata[0].bday}}</el-descriptions-item>
            <el-descriptions-item label="Place of Birth">{{viewdata[0].birthplace}}</el-descriptions-item>
            <el-descriptions-item label="Civil Status">{{viewdata[0].civilstatus_bg4_id_name}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="margin-top: 1em" title="GOVERNMENT IDENTIFICATION DETAILS" direction="horizontal" :column="1" border>
            <el-descriptions-item label="Philhealth No.">{{viewdata[0].philhealth_id}}</el-descriptions-item>
            <el-descriptions-item label="Unified-CRN No.">{{viewdata[0].unifiedcrn}}</el-descriptions-item>
            <el-descriptions-item label="Driver's License">{{viewdata[0].driverlicense}}</el-descriptions-item>
            <el-descriptions-item label="SSS No.">{{viewdata[0].sss_id}}</el-descriptions-item>
            <el-descriptions-item label="Tin No.">{{viewdata[0].tin_id}}</el-descriptions-item>
            <el-descriptions-item label="Pagibig No.">{{viewdata[0].pagibig_id}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="margin-top: 1em" title="COMPANY ROLE" direction="horizontal" :column="1" border>
          </el-descriptions>
            <el-checkbox disabled v-model="isDivision">Division Head</el-checkbox>
            <el-checkbox disabled v-model="isDepartment">Department Head</el-checkbox>
            <el-checkbox disabled v-model="isSection">Section Head</el-checkbox>
            <el-checkbox disabled v-model="isPaymaster">Payroll Master</el-checkbox>
          <el-descriptions style="margin-top: 1em" title="OTHER IDENTIFICATION DETAILS" direction="horizontal" :column="1" border>
            <el-descriptions-item label="Cocolife ID No.">{{viewdata[0].medicard_code}}</el-descriptions-item>
            <el-descriptions-item label="Acctg Payecode">{{viewdata[0].paye_code}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="margin-top: 1em" title="ADDRESS DETAILS (PERMANENT)" direction="horizontal" :column="1" border>
            <el-descriptions-item label="House \ Unit No.">{{viewdata[0].employee_masterfile_contactaddress[0].address}}</el-descriptions-item>
            <el-descriptions-item label="Street Name">{{viewdata[0].employee_masterfile_contactaddress[0].street}}</el-descriptions-item>
            <el-descriptions-item label="Zipcode"></el-descriptions-item>
            <el-descriptions-item label="Region"></el-descriptions-item>
            <el-descriptions-item label="Province"></el-descriptions-item>
            <el-descriptions-item label="City \ Municipality">{{viewdata[0].employee_masterfile_contactaddress[0].address}}</el-descriptions-item>
            <el-descriptions-item label="Barangay">{{viewdata[0].employee_masterfile_contactaddress[0].brgy}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="margin-top: 1em" title="ADDRESS DETAILS (PRESENT)" direction="horizontal" :column="1" border>
            <el-descriptions-item label="House \ Unit No.">{{viewdata[0].employee_masterfile_contactaddress[1].address}}</el-descriptions-item>
            <el-descriptions-item label="Street Name">{{viewdata[0].employee_masterfile_contactaddress[1].street}}</el-descriptions-item>
            <el-descriptions-item label="Zipcode"></el-descriptions-item>
            <el-descriptions-item label="Region"></el-descriptions-item>
            <el-descriptions-item label="Province"></el-descriptions-item>
            <el-descriptions-item label="City \ Municipality"></el-descriptions-item>
            <el-descriptions-item label="Barangay">{{viewdata[0].employee_masterfile_contactaddress[1].brgy}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="margin-top: 1em" title="ADDRESS DETAILS (PROVINCIAL)" direction="horizontal" :column="1" border>
            <el-descriptions-item label="House \ Unit No.">{{viewdata[0].employee_masterfile_contactaddress[2].address}}</el-descriptions-item>
            <el-descriptions-item label="Street Name">{{viewdata[0].employee_masterfile_contactaddress[2].street}}</el-descriptions-item>
            <el-descriptions-item label="Zipcode"></el-descriptions-item>
            <el-descriptions-item label="Region"></el-descriptions-item>
            <el-descriptions-item label="Province"></el-descriptions-item>
            <el-descriptions-item label="City \ Municipality"></el-descriptions-item>
            <el-descriptions-item label="Barangay">{{viewdata[0].employee_masterfile_contactaddress[2].brgy}}</el-descriptions-item>
          </el-descriptions>
        </el-col>
        <el-col :span="11" style="margin-left: 2em">
          <el-descriptions title="CONTACT DETAILS" direction="horizontal" :column="1" border>
            <el-descriptions-item label="Home Tel. No.">{{viewdata[0].employee_masterfile_contact[0].landline_no}}</el-descriptions-item>
            <el-descriptions-item label="Mobile No.">{{viewdata[0].employee_masterfile_contact[0].mobile1_no}}</el-descriptions-item>
            <el-descriptions-item label="Personal Email Address">{{viewdata[0].employee_masterfile_contact[0].emailadd_personal}}</el-descriptions-item>
            <el-descriptions-item label="Web Gmail Email Address">{{viewdata[0].employee_masterfile_contact[0].emailadd_gmail}}</el-descriptions-item>
            <el-descriptions-item label="Company Email Address">{{viewdata[0].employee_masterfile_contact[0].emailadd_company}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="margin-top: 1em" title="INTERNAL EMPLOYMENT DETAILS" direction="horizontal" :column="1" border>
            <el-descriptions-item label="Employee Status">{{viewdata[0].isstatus_name}}</el-descriptions-item>
            <el-descriptions-item label="Employment Type">{{viewdata[0].employmenttype_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Employment Status">{{viewdata[0].bes_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Date Hired">{{viewdata[0].datehired}}</el-descriptions-item>
            <el-descriptions-item label="Date Regularized">{{viewdata[0].dateregular}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="margin-top: 3em" title="" direction="horizontal" :column="1" border>
            <el-descriptions-item label="Inactive Category"></el-descriptions-item>
            <el-descriptions-item label="Date Received by HR">{{viewdata[0].inactive_datereceived_by_hr}}</el-descriptions-item>
            <el-descriptions-item label="Inactive Date (From)">{{viewdata[0].inactive_date_from}}</el-descriptions-item>
            <el-descriptions-item label="Inactive Date (To)">{{viewdata[0].inactive_date_to}}</el-descriptions-item>
            <el-descriptions-item label="Inactive Reason/s">{{viewdata[0].inactive_reason}}</el-descriptions-item>
            <el-descriptions-item label="Expected Date Return">{{viewdata[0].inactive_date_return}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="margin-top: 3em" title="" direction="horizontal" :column="1" border>
            <el-descriptions-item label="Resigned Status">{{viewdata[0].resign_bes_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Date Received by HR">{{viewdata[0].resigned_datereceived_by_hr}}</el-descriptions-item>
            <el-descriptions-item label="Date Resigned">{{viewdata[0].dateresigned}}</el-descriptions-item>
            <el-descriptions-item label="Resigned Effectivity Date">{{viewdata[0].dateresigned_effectivity}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="margin-top: 3em" title="DEPARTMENT AND JOB DESCRIPTION" direction="horizontal" :column="1" border>
            <el-descriptions-item label="Position Title">{{viewdata[0].bp_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Job Level">{{viewdata[0].bj_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Department">{{viewdata[0].bd_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Home Based Branch">{{viewdata[0].bbl_id_homebased_name}}</el-descriptions-item>
            <el-descriptions-item label="Deploying Branch">{{viewdata[0].bbl_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Job Rank">{{viewdata[0].bjr_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Function">{{viewdata[0].bf_id_name}}</el-descriptions-item>
            <el-descriptions-item label="Section">{{viewdata[0].bds_id_name}}</el-descriptions-item>
          </el-descriptions>
        </el-col>
      </el-row>
    </el-dialog>
    <el-drawer title="Search Filter" :visible.sync="drawer" direction="btt" size="50%">
      <div class="drawer">
        <el-form label-width="120px">
          <el-form-item label="Search By">
            <el-select v-model="search.url" @change="resetValue">
              <el-option label="ID" value="employee-with-id"></el-option>
              <el-option label="Name" value="employee-name-like"></el-option>
              <el-option label="Date Hired" value="employee-datehired-list"></el-option>
              <el-option label="Department" value="employee-department-list"></el-option>
              <el-option label="Job Title" value="employee-job-title-list"></el-option>
              <el-option label="Employee Status" value="employee-employment-status-list"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="search.url === 'employee-with-id'" label="Employee ID">
            <el-input v-model="search.value" placeholder="Employee ID"></el-input>
          </el-form-item>
          <el-form-item v-if="search.url === 'employee-name-like'" label="Employee Name">
            <el-input v-model="search.value" placeholder="Employee Name"></el-input>
          </el-form-item>
          <el-form-item v-if="search.url === 'employee-datehired-list'" label="Date Hired">
          <el-date-picker v-model="search.value" type="daterange" range-separator="To" start-placeholder="From" end-placeholder="To" format="yyyy-dd-MM" value-format="yyyy-dd-MM"></el-date-picker>
          </el-form-item>
          <el-form-item v-if="search.url === 'employee-department-list'" label="Department">
            <el-select v-model="search.value" filterable placeholder="Select">
              <el-option v-for="department in departments" :key="department.id" :label="department.name" :value="department.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="search.url === 'employee-job-title-list'" label="Job Title">
            <el-select v-model="search.value" filterable placeholder="Select">
              <el-option v-for="title in titles" :key="title.id" :label="title.name" :value="title.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="search.url === 'employee-employment-status-list'" label="Employee Status">
            <el-select v-model="search.value" filterable placeholder="Select">
              <el-option v-for="status in statuses" :key="status.id" :label="status.name" :value="status.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button @click="closeFilterDialog()">Cancel</el-button>
          <el-button type="primary" @click="confirmFilterDialog()">Confirm</el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="Add New Record" :visible.sync="dialog" fullscreen  width="100%">
      <el-form label-width="200px"  label-position="left" ref="employeeForm" :model="employeeForm" :rules="rules">
        <el-row>
          <el-col :span="8">
            <div style="height: 600px;">
              <el-steps :active="active" finish-status="success" align-center direction="vertical">
                <el-step title="Basic Details"></el-step>
                <el-step title="Government Identification Details"></el-step>
                <el-step title="Contact Details"></el-step>
                <el-step title="Other Identification Details"></el-step>
                <el-step title="Internal Employment Details"></el-step>
                <el-step title="Address"></el-step>
                <el-step title="Department & Job Description"></el-step>
              </el-steps>
            </div>
          </el-col>
          <el-col :span="16">
            <div v-if="active === 0">
              <h3>Basic Details</h3>
              <el-form-item label="Type">
                <el-radio-group v-model="employeeForm.isemployee">
                  <el-radio label=1>Employee</el-radio>
                  <el-radio label=2>Non Employee</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="Company">
                <el-select v-model="employeeForm.bc_id">
                  <el-option v-for="companys in company" :key="companys.id" :value="companys.id" :label="companys.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Paymaster" prop="paymaster_em_id">
                <el-select v-model="employeeForm.paymaster_em_id">
                  <el-option v-for="paymasters in paymaster" :key="paymasters.id" :value="paymasters.id" :label="paymasters.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Last Name" prop="lastname">
                <el-input v-model="employeeForm.lastname"></el-input>
              </el-form-item>
              <el-form-item label="First Name" prop="firstname">
                <el-input v-model="employeeForm.firstname" type="text"></el-input>
              </el-form-item>
              <el-form-item label="Middle Name" prop="middlename">
                <el-input v-model="employeeForm.middlename"></el-input>
                <el-checkbox>No Middle Name</el-checkbox>
              </el-form-item>
              <el-form-item label="Maiden Middle Name" prop="maiden_lastname">
                <el-input v-model="employeeForm.maiden_lastname"></el-input>
              </el-form-item>
              <el-form-item label="Suffix" prop="suffix_bg4_id">
                <el-select clearable v-model="employeeForm.suffix_bg4_id">
                  <el-option v-for="suffixes in suffix" :key="suffixes.id" :value="suffixes.id" :label="suffixes.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Nickname" prop="nickname">
                <el-input v-model="employeeForm.nickname"></el-input>
              </el-form-item>
              <el-form-item label="Sex" prop="sex">
                <el-select v-model="employeeForm.sex">
                  <el-option label="Male" :value="'Male'"></el-option>
                  <el-option label="Female" :value="'Female'"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Gender" prop="gender_bg4_id">
                <el-select clearable v-model="employeeForm.gender_bg4_id">
                  <el-option v-for="genders in gender" :key="genders.id" :value="genders.id" :label="genders.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Religion" prop="religion_bg4_id">
                <el-select v-model="employeeForm.religion_bg4_id">
                  <el-option v-for="religions in religion" :key="religions.id" :value="religions.id" :label="religions.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Date of Birth" prop="bday">
                <el-date-picker v-model="employeeForm.bday" type="date" placeholder="Pick a day" value-format="MM/dd/yyyy"></el-date-picker>
              </el-form-item>
              <el-form-item label="Place of Birth" prop="birthplace">
                <el-input v-model="employeeForm.birthplace"></el-input>
              </el-form-item>
              <el-form-item label="Civil Status" prop="civilstatus_bg4_id">
                <el-select v-model="employeeForm.civilstatus_bg4_id">
                  <el-option v-for="civilstatuses in civilstatus" :key="civilstatuses.id" :value="civilstatuses.id" :label="civilstatuses.name"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div v-else-if="active === 1">
              <h3>Government Identification Details</h3>
              <el-form-item label="Philhealth No." prop="philhealth_id">
                <el-input v-model="employeeForm.philhealth_id" type="number"></el-input>
              </el-form-item>
              <el-form-item label="Unified-CRN No." prop="unifiedcrn">
                <el-input v-model="employeeForm.unifiedcrn"></el-input>
              </el-form-item>
              <el-form-item label="Driver's License" prop="driverlicense">
                <el-input v-model="employeeForm.driverlicense"></el-input>
              </el-form-item>
              <el-form-item label="SSS No." prop="sss_id">
                <el-input v-model="employeeForm.sss_id"></el-input>
              </el-form-item>
              <el-form-item label="TIN" prop="tin_id">
                <el-input v-model="employeeForm.tin_id"></el-input>
              </el-form-item>
              <el-form-item label="Pagibig No." prop="pagibig_id">
                <el-input v-model="employeeForm.pagibig_id"></el-input>
              </el-form-item>
              <el-form-item label="Company Role">
                <el-checkbox-group v-model="checklist">
                  <el-checkbox v-model="employeeForm.isdivisionhead" :value="1" label="Division Head"></el-checkbox>
                  <el-checkbox v-model="employeeForm.issectionhead" :value="1" label="Department Head"></el-checkbox>
                  <el-checkbox v-model="employeeForm.ishead" :value="1" label="Section Head"></el-checkbox>
                  <el-checkbox v-model="employeeForm.ispaymaster" :value="1" label="Payroll Master"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
            <div v-else-if="active === 2">
              <h3>Contact Details</h3>
              <el-form-item label="Home Tel. No.">
                <el-input></el-input>
                <el-input></el-input>
                <el-input></el-input>
              </el-form-item>
              <el-form-item label="Mobile Number/s" prop="mobile1_no">
                <el-input v-model="employeeForm.mobile1_no"></el-input>
                <el-input v-model="employeeForm.mobile2_no"></el-input>
                <el-input v-model="employeeForm.mobile3_no"></el-input>
              </el-form-item>
              <el-form-item label="Email Address" prop="emailadd_personal">
                <el-input v-model="employeeForm.emailadd_personal">
                </el-input>
              </el-form-item>
              <el-form-item label="Web GMAIL Email Address" prop="emailadd_gmail">
                <el-input v-model="employeeForm.emailadd_gmail">
                  <template slot="append">@gmail.com</template>
                </el-input>
              </el-form-item>
              <el-form-item label="Company Email Address" prop="emailadd_company">
                <el-input v-model="employeeForm.emailadd_company"></el-input>
              </el-form-item>
            </div>
            <div v-else-if="active === 3">
              <h3>Other Identification Details</h3>
              <el-form-item label="Cocolife ID Number">
                <el-input v-model="employeeForm.medicard_code"></el-input>
              </el-form-item>
              <el-form-item label="Acctg Payecode">
                <el-input v-model="employeeForm.paye_code"></el-input>
              </el-form-item>
            </div>
            <div v-else-if="active === 4">
              <h3>Internal Employment Details</h3>
              <el-form-item label="Employee Status" prop="isstatus">
                <el-select v-model="employeeForm.isstatus">
                  <el-option v-for="employee_statuses in employee_status" :key="employee_statuses.id" :value="employee_statuses.id" :label="employee_statuses.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Employment Type" prop="employmenttype_id">
                <el-select v-model="employeeForm.employmenttype_id">
                  <el-option v-for="employee_types in employee_type" :key="employee_types.id" :value="employee_types.id" :label="employee_types.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Employment Status" prop="bes_id">
                <el-select v-model="employeeForm.bes_id">
                  <el-option v-for="employment_statuses in employment_status" :key="employment_statuses.id" :value="employment_statuses.id" :label="employment_statuses.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Date Hired" prop="datehired">
                <el-date-picker v-model="employeeForm.datehired" type="date" placeholder="Pick a date" value-format="MM/dd/yyyy" @change="dateChange"></el-date-picker>
              </el-form-item>
              <el-form-item label="Date Regularized" prop="dateregular">
                <el-date-picker v-model="employeeForm.dateregular" type="date" placeholder="Pick a date" value-format="MM/dd/yyyy"></el-date-picker>
              </el-form-item>
              <el-form-item label="Inactive Category">
                <el-input></el-input>
              </el-form-item>
              <el-form-item label="Date Received By HR">
                <el-date-picker v-model="employeeForm.inactive_datereceived_by_hr" type="date" placeholder="Pick a date" value-format="MM/dd/yyyy"></el-date-picker>
              </el-form-item>
              <el-form-item label="Inactive Date">
                <el-date-picker v-model="employeeForm.inactive_date_to" type="date" placeholder="Pick a date" value-format="MM/dd/yyyy"></el-date-picker>
              </el-form-item>
              <el-form-item label="Inactive Reason/s">
                <el-input v-model="employeeForm.inactive_reason"></el-input>
              </el-form-item>
              <el-form-item label="Expected Date Return">
                <el-date-picker type="date" placeholder="Pick a date" value-format="MM/dd/yyyy"></el-date-picker>
              </el-form-item>
              <el-form-item label="Resigned Status">
                <el-input></el-input>
              </el-form-item>
              <el-form-item label="Date Received By HR">
                <el-date-picker type="date" placeholder="Pick a date" value-format="MM/dd/yyyy"></el-date-picker>
              </el-form-item>
              <el-form-item label="Date Resigned">
                <el-date-picker v-model="employeeForm.dateresigned" type="date" placeholder="Pick a date" value-format="MM/dd/yyyy"></el-date-picker>
              </el-form-item>
              <el-form-item label="Resigned Effectivity Date">
                <el-input></el-input>
              </el-form-item>
            </div>
            <div v-else-if="active === 5" v-for="(file, index) in employeeForm.contact_address" :key="index">
              <h3>{{employeeForm.contact_address[index].title}}</h3>
              <el-form-item label="House Unit No.">
                <el-input v-model="employeeForm.contact_address[index].address"></el-input>
              </el-form-item>
              <el-form-item label="Street Name">
                <el-input v-model="employeeForm.contact_address[index].street"></el-input>
              </el-form-item>
              <el-form-item label="Zip Code">
                <el-select  v-model="employeeForm.contact_address[index].zipcode" filterable @change="fillAddress(employeeForm.contact_address[index])">
                  <el-option v-for="(zipcodes, index) in zipcode" :key="index" :value="zipcodes.id" :label="zipcodes.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Region">
                <el-input v-model="employeeForm.contact_address[index].region"></el-input>
              </el-form-item>
              <el-form-item label="Province">
                <el-input v-model="employeeForm.contact_address[index].province"></el-input>
              </el-form-item>
              <el-form-item label="City/Municipality">
                <el-input v-model="employeeForm.contact_address[index].city"></el-input>
              </el-form-item>
              <el-form-item label="Barangay">
                <el-input v-model="employeeForm.contact_address[index].brgy"></el-input>
              </el-form-item>
            </div>
            <div v-else-if="active === 6">
              <h3>Department & Job Description</h3>
              <el-form-item label="Position Title" prop="bp_id">
                <el-select v-model="employeeForm.bp_id">
                  <el-option filterable v-for="position_titles in position_title" :key="position_titles.id" :value="position_titles.id" :label="position_titles.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Job Level" prop="bj_id">
                <el-select v-model="employeeForm.bj_id">
                  <el-option filterable v-for="job_levels in job_level" :key="job_levels.id" :value="job_levels.id" :label="job_levels.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Department" prop="bd_id">
                <el-select v-model="employeeForm.bd_id">
                  <el-option filterable v-for="departments in departments" :key="departments.id" :value="departments.id" :label="departments.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Home Based Branch" prop="bbl_id_homebased">
                <el-select v-model="employeeForm.bbl_id_homebased">
                  <el-option filterable v-for="homebase_locations in homebase_location" :key="homebase_locations.id" :value="homebase_locations.id" :label="homebase_locations.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Deploying Branch" prop="bbl_id">
                <el-select v-model="employeeForm.bbl_id">
                  <el-option filterable v-for="homebase_locations in homebase_location" :key="homebase_locations.id" :value="homebase_locations.id" :label="homebase_locations.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Job Rank">
                <el-select v-model="employeeForm.bjr_id">
                  <el-option filterable v-for="job_ranks in job_rank" :key="job_ranks.id" :value="job_ranks.id" :label="job_ranks.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Function">
                <el-select v-model="employeeForm.bf_id">
                  <el-option filterable v-for="functions in function_title" :key="functions.id" :value="functions.id" :label="functions.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Section">
                <el-select v-model="employeeForm.bds_id">
                  <el-option filterable v-for="sections in section" :key="sections.id" :value="sections.id" :label="sections.name"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="previous">Previous</el-button>
          <el-button v-if="active === 6" type="primary" @click="confirm">Confirm</el-button>
          <el-button v-else @click.prevent="next()">Next</el-button>
      </span>
    </el-dialog>
    <el-table :data="data" border class="hidden-sm-and-down">
      <el-table-column label="ID NUMBER" prop="empid"></el-table-column>
      <el-table-column label="EMPLOYEE" prop="name"></el-table-column>
      <el-table-column label="COMPANY" prop="bc_id_name"></el-table-column>
      <el-table-column label="DEPARTMENT" prop="bd_id_name"></el-table-column>
      <el-table-column label="DATE HIRED" prop="datehired">
        <template slot-scope="props">
          {{ formatDate(props.row.datehired) }}
        </template>
      </el-table-column>
      <el-table-column label="STATUS" prop="bes_id_name"></el-table-column>
      <el-table-column label="ACTIONS" fixed="right" width="170">
        <template slot-scope="scope">
          <div class="fixed-row">
            <el-tooltip class="item" effect="dark" content="View" placement="top-start">
              <el-button size="mini" type="primary" @click="view(scope.row.id)"><i class="el-icon-document-checked"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Edit" placement="top-start">
              <el-button size="mini" type="primary" @click="openEditor(scope.row.id)"><i class="el-icon-edit"></i></el-button>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="controls">
      <!-- <div class="control">
        <p>Items per page: </p>
        <el-select v-model="items">
          <el-option :label="5" :value="5"></el-option>
          <el-option :label="10" :value="10"></el-option>
          <el-option :label="25" :value="25"></el-option>
          <el-option :label="50" :value="50"></el-option>
          <el-option :label="100" :value="100"></el-option>
        </el-select>
      </div> -->
      <div class="control">
          <el-button-group>
            <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage()" :disabled="!hasPreviousPage">Previous Page</el-button>
            <el-button type="primary" @click="nextPage()" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
          </el-button-group>
      </div>
    </div>
  </el-card>
</template>

<script>
import { format } from 'date-fns'

export default {
  data () {
    return this.initialData()
  },
  async mounted () {
    await this.getEmploymentStatus()
    await this.getJobTitles()
    await this.getDepartments()
    await this.getSuffix()
    await this.getSex()
    await this.getGender()
    await this.getReligion()
    await this.getCivilStatus()
    await this.getEmployeeStatus()
    await this.getEmployeeType()
    await this.getPositionTitle()
    await this.getJobLevel()
    await this.getHomeBaseLocation()
    await this.getJobRank()
    await this.getFunction()
    await this.getSection()
    await this.getPaymaster()
    await this.getZipcode()
    // await this.getEmployees()
    await this.getCompany()
  },
  methods: {
    initialData () {
      return {
        active: 0,
        items: 5,
        drawer: false,
        dialog: false,
        dialogView: false,
        data: [],
        viewdata: [
          {
            bc_id_name: null,
            employee_masterfile_contact: [
              {
                landline_no: null,
                mobile1_no: null,
                mobile2_no: null,
                mobile3_no: null,
                emailadd_personal: null,
                emailadd_company: null,
                emailadd_gmail: null
              }
            ],
            employee_masterfile_contactaddress: [
              {
                address: null,
                street: null,
                brgy: null
              },
              {
                address: null,
                street: null,
                brgy: null
              },
              {
                address: null,
                street: null,
                brgy: null
              }
            ]
          }
        ],
        suffix: [],
        gender: [],
        religion: [],
        civilstatus: [],
        employee_status: [],
        employee_type: [],
        employment_status: [],
        position_title: [],
        job_level: [],
        homebase_location: [],
        job_rank: [],
        function_title: [],
        section: [],
        paymaster: [],
        zipcode: [],
        company: [],
        isPaymaster: false,
        isDivision: false,
        isSection: false,
        isDepartment: false,
        location_branch: [],
        search: {
          url: null,
          value: null
        },
        employeeForm: {
          sag_id: 1,
          paymaster_em_id: null,
          suffix_bg4_id: null,
          sex: null,
          gender_bg4_id: null,
          religion_bg4_id: null,
          civilstatus_bg4_id: null,
          isstatus: null,
          employmenttype_id: null,
          bp_id: null,
          bj_id: null,
          bbl_id_homebased: null,
          bbl_id: null,
          bjr_id: null,
          bf_id: null,
          bds_id: null,
          datehired: null,
          bc_id: null,
          dateregular: null,
          mre_id: null,
          ma_id: null,
          paye_code: null,
          empid_temp: null,
          medicard_code: null,
          lastname: null,
          firstname: null,
          nickname: null,
          middlename: null,
          maiden_lastname: null,
          bday: null,
          age: null,
          dependent: 0,
          birthplace: null,
          philhealth_id: null,
          sss_id: null,
          gsis_id: null,
          tin_id: null,
          pagibig_id: null,
          unifiedcrn: null,
          driverlicense: null,
          isemployee: 0,
          isstatus_payroll: null,
          isdivisionhead: 0,
          ishead: 0,
          issectionhead: 0,
          isbranchrh: 0,
          isbranchbos: 0,
          isfingerprint: 0,
          isbdaygreet: 0,
          istimekeepsend: 0,
          ispaymaster: 0,
          bp_id_count: 0,
          br_id: 0,
          bj_id_count: 0,
          bjr_id_count: 0,
          bf_id_count: 0,
          record_openby: 0,
          record_isopen: 0,
          isforce_logout: 0,
          bd_id: null,
          bes_id: null,
          inactive_bes_id: 0,
          inactive_date_from: null,
          inactive_date_to: null,
          inactive_date_return: null,
          inactive_reason: null,
          inactive_datereceived_by_hr: null,
          resign_bes_id: 0,
          dateresigned: null,
          resigned_datereceived_by_hr: null,
          resign_is_clearance: 0,
          basicsalary: 0,
          istestonly: 0,
          mobile1_no: null,
          mobile2_no: null,
          mobile3_no: null,
          landline_no: null,
          emailadd_gmail: '',
          emailadd_company: null,
          mobile1_network_bg4_id: 1,
          mobile2_network_bg4_id: 2,
          mobile3_network_bg4_id: 3,
          landline_country: null,
          landline_areacode: null,
          emailadd_personal: null,
          emailadd_payroll: null,
          emailadd_usp: null,
          contact_address: [
            {
              title: 'Address Details (Permanent)',
              address: '',
              street: '',
              brgy: '',
              zipcode: '',
              address_type: 1,
              region: '',
              province: '',
              city: '',
              brpc_id: 0,
              br_id: ''
            },
            {
              title: 'Address Details (Present)',
              address: '',
              street: '',
              brgy: '',
              zipcode: '',
              address_type: 2,
              region: '',
              province: '',
              city: '',
              brpc_id: 0,
              br_id: ''
            },
            {
              title: 'Address Details (Provincial)',
              address: '',
              street: '',
              brgy: '',
              zipcode: '',
              address_type: 3,
              region: '',
              province: '',
              city: '',
              brpc_id: 0,
              br_id: ''
            }
          ]
        },
        rules: {
          firstname: [
            { required: true, message: 'Firstname is Required', trigger: 'blur' }
          ],
          lastname: [
            { required: true, message: 'Lastname is Required', trigger: 'blur' }
          ],
          paymaster_em_id: [
            { required: true, message: 'Paymaster is Required', trigger: 'change' }
          ],
          isemployee: [
            { required: true, message: 'Type is Required', trigger: 'change' }
          ],
          middlename: [
            { required: true, message: 'Middlename is Required', trigger: 'blur' }
          ],
          nickname: [
            { required: true, message: 'Nickname is Required', trigger: 'blur' }
          ],
          sex: [
            { required: true, message: 'Sex is Required', trigger: 'change' }
          ],
          religion_bg4_id: [
            { required: true, message: 'Religion is Required', trigger: 'change' }
          ],
          bday: [
            { required: true, message: 'Birthdate is Required', trigger: 'change' }
          ],
          birthplace: [
            { required: true, message: 'Birthplace is Required', trigger: 'blur' }
          ],
          civilstatus_bg4_id: [
            { required: true, message: 'Civil Status is Required', trigger: 'change' }
          ],
          emailadd_personal: [
            { required: true, message: 'Email Personal is Required', trigger: 'blur' }
          ],
          emailadd_gmail: [
            { required: true, message: 'Gmail  is Required', trigger: 'blur' }
          ],
          isstatus: [
            { required: true, message: 'Employee Status is Required', trigger: 'change' }
          ],
          bes_id: [
            { required: true, message: 'Employment Status is Required', trigger: 'change' }
          ],
          employmenttype_id: [
            { required: true, message: 'Employment Type is Required', trigger: 'change' }
          ],
          datehired: [
            { required: true, message: 'Date hired is Required', trigger: 'change' }
          ],
          dateregular: [
            { required: true, message: 'Date regular is Required', trigger: 'change' }
          ],
          bp_id: [
            { required: true, message: 'Position Title is Required', trigger: 'change' }
          ],
          bj_id: [
            { required: true, message: 'Job Level is Required', trigger: 'change' }
          ],
          bd_id: [
            { required: true, message: 'Department is Required', trigger: 'change' }
          ],
          bbl_id_homebased: [
            { required: true, message: 'Home Based Branch is Required', trigger: 'change' }
          ],
          bbl_id: [
            { required: true, message: 'Deploying Branch is Required', trigger: 'change' }
          ],
          mobile1_no: [
            { required: true, message: 'Mobile Number is Required', trigger: 'blur' }
          ],
          bds_id: [
            { required: true, message: 'Section is Required', trigger: 'change' }
          ]
        },
        titles: [],
        departments: [],
        checklist: [],
        page: 1,
        hasNextPage: false,
        hasPreviousPage: false
      }
    },
    nextPage () {
      this.page++
      this.getSearch()
      // this.getEmployees()
    },
    prevPage () {
      this.page--
      this.getSearch()
      // this.getEmployees()
    },
    closeFilterDialog () {
      this.drawer = false
      this.search.type = null
      this.search.value = null
    },
    confirmFilterDialog () {
      this.drawer = false
      this.getSearch()
    },
    resetValue () {
      this.search.value = null
    },
    dateChange (date) {
      if (date !== null) {
        const dateregular = new Date(date)
        dateregular.setMonth(dateregular.getMonth() + 6)
        this.employeeForm.dateregular = format(dateregular, 'MM/dd/yyyy')
      } else {
        this.employeeForm.dateregular = null
      }
    },
    getEmploymentStatus () {
      this.$http
        .get('backend.employment-status-list')
        .then(res => {
          this.employment_status = res.data.body
        })
    },
    getJobTitles () {
      this.$http
        .get('backend.global4-list/30')
        .then(res => {
          this.titles = res.data.body
        })
    },
    getDepartments () {
      this.$http
        .get('backend.department-get-list')
        .then(res => {
          this.departments = res.data.body
        })
    },
    getSuffix () {
      this.$http
        .get('backend.global4-list/42')
        .then(res => {
          this.suffix = res.data.body
        })
    },
    getSex () {
      this.$http
        .get('backend.global4-list/43')
        .then(res => {
          this.sex = res.data.body
        })
    },
    getGender () {
      this.$http
        .get('backend.global4-list/43')
        .then(res => {
          this.gender = res.data.body
        })
    },
    getReligion () {
      this.$http
        .get('backend.global4-list/1')
        .then(res => {
          this.religion = res.data.body
        })
    },
    getCivilStatus () {
      this.$http
        .get('backend.global4-list/27')
        .then(res => {
          this.civilstatus = res.data.body
        })
    },
    getEmployeeStatus () {
      this.$http
        .get('backend.status-list')
        .then(res => {
          this.employee_status = res.data.body
        })
    },
    getEmployeeType () {
      this.$http
        .get('backend.global4-list/40')
        .then(res => {
          this.employee_type = res.data.body
        })
    },
    getPositionTitle () {
      this.$http
        .get('backend.global4-list/30')
        .then(res => {
          this.position_title = res.data.body
        })
    },
    getJobLevel () {
      this.$http
        .get('backend.global4-list/31')
        .then(res => {
          this.job_level = res.data.body
        })
    },
    getHomeBaseLocation () {
      this.$http
        .get('locationinfo.locations/1')
        .then(res => {
          this.homebase_location = res.data.body
        })
    },
    getJobRank () {
      this.$http
        .get('backend.global4-list/28')
        .then(res => {
          this.job_rank = res.data.body
        })
    },
    getFunction () {
      this.$http
        .get('backend.global4-list/29')
        .then(res => {
          this.function_title = res.data.body
        })
    },
    getSection () {
      this.$http
        .get('backend.department.section-list')
        .then(res => {
          this.section = res.data.body
        })
    },
    getPaymaster () {
      this.$http
        .get('em/employee-paymaster')
        .then(res => {
          this.paymaster = res.data.body
        })
    },
    getZipcode () {
      this.$http
        .get('backend.region-province-city-zipcode-list')
        .then(res => {
          this.zipcode = res.data.body
        })
    },
    getCompany () {
      this.$http
        .get('backend.company-list')
        .then(res => {
          this.company = res.data.body
        })
    },
    fillAddress (data) {
      this.$http
        .get(`backend.region-province-city-by-zipcode/${data.zipcode}`)
        .then(res => {
          if (data.address_type === 1) {
            this.employeeForm.contact_address[0].brgy = res.data.body[0].brpcb_id_name
            this.employeeForm.contact_address[0].brpc_id = res.data.body[0].brpc_id
            this.employeeForm.contact_address[0].region = res.data.body[0].br_id_name
            this.employeeForm.contact_address[0].br_id = res.data.body[0].br_id
            this.employeeForm.contact_address[0].province = res.data.body[0].brp_id_name
            this.employeeForm.contact_address[0].city = res.data.body[0].brpc_id_name
          } else if (data.address_type === 2) {
            this.employeeForm.contact_address[1].brgy = res.data.body[0].brpcb_id_name
            this.employeeForm.contact_address[1].brpc_id = res.data.body[0].brpc_id
            this.employeeForm.contact_address[1].region = res.data.body[0].br_id_name
            this.employeeForm.contact_address[1].br_id = res.data.body[0].br_id
            this.employeeForm.contact_address[1].province = res.data.body[0].brp_id_name
            this.employeeForm.contact_address[1].city = res.data.body[0].brpc_id_name
          } else if (data.address_type === 3) {
            this.employeeForm.contact_address[2].brgy = res.data.body[0].brpcb_id_name
            this.employeeForm.contact_address[2].brpc_id = res.data.body[0].brpc_id
            this.employeeForm.contact_address[2].region = res.data.body[0].br_id_name
            this.employeeForm.contact_address[2].br_id = res.data.body[0].br_id
            this.employeeForm.contact_address[2].province = res.data.body[0].brp_id_name
            this.employeeForm.contact_address[2].city = res.data.body[0].brpc_id_name
          }
        })
    },
    getSearch () {
      let url = ''
      if (this.search.url === 'employee-datehired-list') {
        url = `${this.search.url}/${this.search.value[0]}/${this.search.value[1]}?page=${this.page}`
      } else if (this.search.url === 'employee-name-like') {
        url = `${this.search.url}/${this.search.value}/${this.items}?page=${this.page}`
      } else {
        url = `${this.search.url}/${this.search.value}?page=${this.page}`
      }
      this.$http
        .get(url)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body.data : []
          this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
          this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
        })
    },
    // getEmployees () {
    //   this.$http.get(`get.employee-list/${this.items}?page=${this.page}`)
    //     .then(res => {
    //       this.data = res.data.StatusCode ? res.data.body.data : []
    //       this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
    //       this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
    //     })
    // },
    view (id) {
      this.dialogView = true
      this.$http
        .get(`em/employee/${id}`)
        .then(res => {
          this.viewdata = res.data.StatusCode ? res.data.body : []
          if (this.viewdata[0].ispaymaster === '1') {
            this.isPaymaster = true
          }
          if (this.viewdata[0].isdivisionhead === '1') {
            this.isDivision = true
          }
          if (this.viewdata[0].issectionhead === '1') {
            this.isSection = true
          }
        })
    },
    confirm () {
      this.$refs.employeeForm.validate((valid) => {
        if (valid) {
          this.addRecord()
        } else {
          return false
        }
      })
    },
    addRecord () {
      this.$http
        .post('em/create-employee', this.employeeForm)
        .then(() => {
          this.dialog = false
          this.reset()
          this.$message({
            message: 'New Employee Record Added',
            type: 'success'
          })
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    },
    openEditor (id) {
      this.civilstatus = []
      const employeeForm = {
        id: id
      }
      this.$http
        .get(`em/employee/${id}`, employeeForm)
        .then(res => {
          this.employeeForm.firstname = res.data.body[0].firstname
          this.employeeForm.lastname = res.data.body[0].lastname
          this.employeeForm.middlename = res.data.body[0].middlename
          this.employeeForm.nickname = res.data.body[0].nickname
          this.employeeForm.bday = res.data.body[0].bday
          this.employeeForm.birthplace = res.data.body[0].birthplace
          this.civilstatus.push({
            id: res.data.body[0].civilstatus_bg4_id,
            name: res.data.body[0].civilstatus_bg4_id_name
          })
          this.getCivilStatus()
          this.dialog = true
        })
    },
    reset () {
      this.$refs.employeeForm.resetFields()
    },
    next () {
      this.$refs.employeeForm.validate((valid) => {
        if (valid) {
          this.active++
        } else {
          return false
        }
      })
    },
    previous () {
      if (this.active !== 0) {
        this.active--
      }
    },
    formatDate (date, dateFormat = 'iii, dd MMM yyyy') {
      return format(new Date(date), dateFormat)
    }
  }
}
</script>

<style scoped>
/* .el-input {
  width: auto !important;
} */
</style>
